import {formatDate} from '@angular/common';

export class Order {
  public idOrder: number;
  public Symbol: string;
  public Trader: string;
  public MPID: string;
  public OrdQty: number;
  public OrdPx: number;
  public OrdTime: Date;
  public CancelTime: Date;
  public OfferTime: Date;
  public OfferQty: number;
  public OfferPx: number;
  public ExecQty: number;
  public ExecPx: number;
  public ExecTime: Date;
  public idSource: number;
  public idReason: number;
  public OrdType: number;
  public OrderStatus: number;
  public CliOrdId: string;
  public TIF: number;
  public MktOrdQty: number;
  public MktExecQty: number;
  public ExpiryTime: Date;
  public MktStatus: string;
  public idEntity: number;
  private readonly time: any;
  statusStyle: string;
  isSelectable: boolean;
  locateStatus: number;
  locateStatusText: string;


  constructor(data: any) {
    if (data.OrdTime) {
      if (data.OrdTime.time) {
        this.OrdTime = new Date(data.OrdTime.time);
      } else {
        this.OrdTime = new Date(data.OrdTime);
      }
    }
    if (data.ExecTime) {
      if (data.ExecTime.time) {
        this.ExecTime = new Date(data.ExecTime.time);
      } else {
        this.ExecTime = new Date(data.ExecTime);
      }
    }

    if (data.CancelTime) {
      if (data.CancelTime.time) {
        this.CancelTime = new Date(data.CancelTime.time);
      } else {
        this.CancelTime = new Date(data.CancelTime);
      }
    }
    if (data.OfferTime) {
      if (data.OfferTime.time) {
        this.OfferTime = new Date(data.OfferTime.time);
      } else {
        this.OfferTime = new Date(data.OfferTime);
      }
    }

    if (this.OrderStatus === 1 || this.OrderStatus === 2) {
      this.time = this.ExecTime;
    } else if (this.OrderStatus === 4 || this.OrderStatus === 8) {
      this.time = this.CancelTime;
    } else {
      this.time = this.OrdTime;
    }

    if (this.time) {
      this.time = formatDate(this.time, 'HH:mm:ss', 'en-US');
    }

    if (data.ExpiryTime) {
      if (data.ExpiryTime.time && data.ExpiryTime.year !== 70) {
        this.ExpiryTime = new Date(data.ExpiryTime.time);
      } else {
        this.ExpiryTime = null;
      }
    }

    this.idOrder = data.idOrder;
    this.Symbol = data.Symbol;
    this.Trader = data.Trader;
    this.MPID = data.MPID;
    this.OrdQty = data.OrdQty;
    this.OrdPx = data.OrdPx;
    this.OfferQty = data.OfferQty;
    this.OfferPx = data.OfferPx;
    this.ExecQty = data.ExecQty;
    this.ExecPx = data.ExecPx;
    this.idSource = data.idSource;
    this.idReason = data.idReason;
    this.OrdType = data.OrdType;
    this.OrderStatus = data.OrderStatus;
    this.CliOrdId = data.CliOrdId;
    this.TIF = data.TIF;
    this.MktOrdQty = data.MktOrdQty;
    this.MktExecQty = data.MktExecQty;
    this.MktStatus = data.MktStatus;
    this.idEntity = data.idEntity;

    switch (data.OrderStatus) {
      case -1:
        this.locateStatusText = 'New';
        break;
      case 0:
        this.locateStatusText = 'Open';
        break;
      case 1:
        this.locateStatusText = 'Partial';
        break;
      case 2:
        this.locateStatusText = 'Filled';
        break;
      case 3:
      case 5:
        this.locateStatusText = 'Pending';
        break;
      case 8:
        this.locateStatusText = 'Rejected';
        break;
      default:
        this.locateStatusText = 'Cancelled';
        break;
    }

    switch (data.OrderStatus) {
      case 0:
        this.statusStyle = 'status-open';
        break;
      case 1:
      case 2:
        if (data.OrdType === 5) {
          this.statusStyle = 'status-overnight';
        } else {
          this.statusStyle = 'status-fill';
        }
        break;
      case 3:
      case 5:
        this.statusStyle = 'status-pend';
        break;
      case 8:
        this.statusStyle = 'status-rejected';
        break;
      default:
        this.statusStyle = 'status-cancel';
        break;
    }
    this.isSelectable = (data.OrderStatus === 5 || data.OrderStatus === 0) ? true : false;
  }
}
