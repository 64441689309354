import 'ag-grid-community';
import {Component, Inject, Injectable, OnDestroy, OnInit} from '@angular/core';
import {GridOptions} from 'ag-grid-community';
import {CellFormatter} from '../../shared/grid/cell-formatter';
import {Subscription} from 'rxjs';
import {Utils} from '../../shared/utils';
import {User} from '../../authentication/User/user';
import {InventoryRequest} from './InventoryRequest';
import {environment} from '../../../environments/environment';
import {AngularFireAuth} from '@angular/fire/auth';
import {HttpClient} from '@angular/common/http';
import {InventoryRequestStreamService} from '../../locates/stream/InventoryRequestStream.service';
import {resolve} from '@angular-devkit/core';

@Component({
  selector: 'app-inventoryrequestgrid',
  templateUrl: './InventoryRequest.component.html',
  styleUrls: ['./InventoryRequest.component.scss']

})

export class InventoryRequestGridComponent implements OnInit, OnDestroy {
  public gridApi;
  private gridColumnApi;
  public gridOptions: GridOptions;
  private _sub: Subscription;
  public hasRowsSelected = false;
  public currentUser: User;
  public isManager;
  public rows: InventoryRequest[] = [];

  constructor(
    private afAuth: AngularFireAuth,
    private httpClient: HttpClient,
    private inventoryRequestService: InventoryRequestStreamService
  ) {
    this.currentUser = new User(JSON.parse(localStorage.getItem('User')));
    this.isManager = this.currentUser.isManager();
    this.defineColumns();
    this.defineGridOptions();
  }

  getContextMenuItems(params) {
    const result = [
      'copy',
      // 'csvExport'
    ];
    return result;
  }

  // Grid Events
  gridOnReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    if (this.gridApi) {
      this._sub = this.inventoryRequestService.inventoryRequests$.subscribe(data => {
        this.gridApi.setRowData(data);
      });
      this.gridApi.sizeColumnsToFit();
    }
  }

  gridSizeChanged(params) {
    this.gridApi.sizeColumnsToFit();
    // Hack to hide horizontal scroll bar
    const colState = this.gridColumnApi.getColumnState();
    this.gridColumnApi.setColumnWidth(colState[colState.length - 1].colId, colState[colState.length - 1].width - 20);
  }

  public onExportButton() {
    const exportFileName = Utils.createFileName('locates-', new Date(), '', '');
    const params = {
      fileName: exportFileName
    };
    // this.gridApi.exportDataAsExcel(params);
    this.gridApi.exportDataAsCsv(params);
  }

  public SetFilter(value: string) {
    const ordTypeFilled = this.gridApi.getFilterInstance('Status');
    ordTypeFilled.setModel({
      filterType: 'text',
      type: 'equals',
      filter: value,
      newRowsAction: 'keep'
    });
    this.gridApi.onFilterChanged();
  }

  public getSelectedRows(): any {
    return this.gridApi.getSelectedRows();
  }

  gridOnCellMouseOut(event) {
  }

  gridOnCellMouseOver(event) {
  }

  gridOnRowSelected(event): boolean {
    this.hasRowsSelected = this.gridApi.getSelectedRows().length > 0;
    return this.hasRowsSelected;
  }

  onCellEditingStopped(event) {
    this.sendInventoryRequest(new InventoryRequest(event.data));
  }

  onCellValueChanged(event) {
    const rowNode = this.gridApi.getDisplayedRowAtIndex(event.rowIndex);
    this.gridApi.flashCells({
      rowNodes: [rowNode],
      columns: ['quantity'],
    });
  }

  // component events
  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('User'));
    // this.inventoryRequestService.subscribeByMpids(this.currentUser.MPIDs);
    this.inventoryRequestService.subscribeByIdUser(this.currentUser.idUser);
  }

  ngOnDestroy() {
    if (this._sub) {
      this._sub.unsubscribe();
    }
  }

  private defineGridOptions() {
    const columns: any = this.defineColumns();
    this.gridOptions = {};
    this.gridOptions.defaultColDef = this.defineDefaultColumns();
    this.gridOptions.columnDefs = columns;
    this.gridOptions.floatingFilter = true;
    this.gridOptions.groupIncludeTotalFooter = false;
    this.gridOptions.rowSelection = 'multiple';
    this.gridOptions.suppressScrollOnNewData = true;
    this.gridOptions.suppressRowClickSelection = true;
    this.gridOptions.enableCellChangeFlash = true;


    // function assignments
    this.gridOptions.getRowNodeId = (params) => this.getRowNodeId(params);
    // this.gridOptions.getRowClass = (params) => CellFormatter.statusStyle(params);
    this.gridOptions.onGridReady = (event) => this.gridOnReady(event);
    this.gridOptions.getContextMenuItems = (event) => this.getContextMenuItems(event);
    this.gridOptions.onCellMouseOver = (event) => this.gridOnCellMouseOver(event);
    this.gridOptions.onCellMouseOut = (event) => this.gridOnCellMouseOut(event);
    this.gridOptions.onCellEditingStopped = (event) => this.onCellEditingStopped(event);
    this.gridOptions.onCellValueChanged = (event) => this.onCellValueChanged(event);
    this.gridOptions.onRowSelected = (event) => this.gridOnRowSelected(event);
  }

  private getRowNodeId(data) {
    return data.idRequest;
  }

  private defineColumns() {
    return [
      {
        minWidth: 40, maxWidth: 40, field: 'checkbox', pinned: true,
        checkboxSelection: true, headerCheckboxSelection: true, hide: this.currentUser.isManager()
      },
      {
        headerName: 'idRequest', minWidth: 75, maxWidth: 75, field: 'idRequest',
        valueFormatter: CellFormatter.num, type: 'numericColumn', hide: true
      },
      // {
      //   headerName: 'Group',
      //   field: 'groupId',
      //   minWidth: 90,
      //   filterParams: {newRowsAction: 'keep'},
      //   maxWidth: 90,
      //   headerTooltip: 'GroupId',
      //   filter: 'agSetColumnFilter',
      //   suppressMenu: false,
      //   menuTabs: ['filterMenuTab'],
      // },
      {
        headerName: 'Symbol',
        minWidth: 110,
        maxWidth: 110,
        field: 'security',
        filter: 'agTextColumnFilter',
        filterParams: {newRowsAction: 'keep'}
      },
      {
        headerName: 'Quantity', minWidth: 75, maxWidth: 75, field: 'quantity',
        valueFormatter: CellFormatter.num, type: 'numericColumn', editable: true,
      },
    ];
  }

  private defineDefaultColumns() {
    return {
      enableCellChangeFlash: true,
      enableFilter: true,
      sortable: true,
      suppressMenu: true,
      floatingFilter: true,
      resizable: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true
      },
      headerCheckboxSelectionFilteredOnly: true
    };
  }

  public addBulkInventoryRequest(items) {
    this.httpClient.put(environment.locateApiEndpoint + 'locate/InventoryRequest/bulkAdd',
      {
        items: items
      }, {
        headers: {
          Authorization: `Bearer ${JSON.parse(JSON.stringify(this.afAuth.auth.currentUser)).stsTokenManager.accessToken}`,
          'Content-Type': 'application/json'
        }
      }).subscribe(function (val) {
    });
  }

  public addInventoryRequest(symbol: string, quantity: number) {
    const user: User = JSON.parse(localStorage.getItem('User'));
    const req: InventoryRequest = new InventoryRequest({
      idUser: user.idUser,
      security: symbol,
      quantity: quantity
    });

    this.sendInventoryRequest(req);
  }

  private sendInventoryRequest(req: InventoryRequest) {
    this.httpClient.put(environment.locateApiEndpoint + 'locate/InventoryRequest/add',
      req, {
        headers: {
          Authorization: `Bearer ${JSON.parse(JSON.stringify(this.afAuth.auth.currentUser)).stsTokenManager.accessToken}`,
          'Content-Type': 'application/json'
        }
      }).subscribe(function (val) {
    });
  }

  public removeSelectedRows() {
    const irs: InventoryRequest[] = this.getSelectedRows();

    this.httpClient.put(environment.locateApiEndpoint + 'locate/InventoryRequest/bulkDelete',
      {items: irs}
      , {
        headers: {
          Authorization: `Bearer ${JSON.parse(JSON.stringify(this.afAuth.auth.currentUser)).stsTokenManager.accessToken}`,
          'Content-Type': 'application/json'
        }
      }).subscribe(function (val) {
    });
    this.gridApi.deselectAll();
  }
}
