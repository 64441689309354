import {formatCurrency, formatDate, formatNumber} from '@angular/common';


export class CellFormatter {
  static localid = 'en-US';

  // public static string localid = LOCALE_ID;
  public static rate: (params: any) => string =
    function (params) {
      return formatCurrency(params.value, CellFormatter.localid, '$', undefined, '1.3-3');
    };

  public static num: (params: any) => string =
    function (params) {
      return formatNumber(params.value, CellFormatter.localid);
    };

  public static timeHMS: (params: any) => string =
    function (params) {
      if (params.value) {
        return formatDate(params.value, 'hh:mm:ss', 'en-US');
      }
    };


  public static orderId: (params: any) => string =
    function (params) {
      if (!params.data) {
        return '';
      } else if (params.data.OrderStatus === 1 || params.data.OrderStatus === 2) {
        return params.value;
      } else {
        return '';
      }
    };

  public static statusStyle: (params: any) => string =
    function (params) {
      if (params.data) {
        switch (params.data.OrderStatus) {
          case -1:
            return 'status-unprocessed';
          case 0:
            return 'status-open';
          case 1:
          case 2:
            if (params.data.OrdType === 5) {
              return 'status-overnight';
            } else {
              return 'status-fill';
            }
          case 3:
          case 5:
            return 'status-pend';
          case 8:
            return 'status-rejected';
          default:
            return 'status-cancel';
        }
      }
    };

  public static mktStatusStyle = function (params) {
    if (params.data) {
      switch (params.data.MktStatus) {
        case 'A':
          return {color: 'black', backgroundColor: 'yellow'};
        case '1':
          return {color: 'white', backgroundColor: '#7CB342'};
        case 'O':
          return {color: 'black', backgroundColor: '#FDD835'};
        default:
          return 'N/A';
      }
    }
  };

  public static statusColumnStyle: (params: any) => string =
    function (params) {
      if (params.data) {
        switch (params.data.OrderStatus) {
          case -1:
            return 'New';
          case 0:
            return 'Open';
          case 1:
          case 2:
              return 'Filled';
          case 3:
          case 5:
            return 'Pending';
          case 8:
            return 'Rejected';
          default:
            return 'Cancelled';
        }
      }
    };

  public static sourceStyleId: (params: any) => string =
    function (params) {
      if (params.data) {
        switch (params.data.OrderStatus) {
          case 0:
            return 'VLCY';
          case 1:
            return 'GSCO';
          default:
            return params.data.idSource;
        }
      }
    };

  public static mktStatusColumnStyle: (params: any) => string =
    function (params) {
      if (params.data) {
        switch (params.data.MktStatus) {
          case 'A':
            return 'Active';
          case '1':
            return 'Partial';
          case '2':
            return 'Filled';
          case '4':
            return 'Cancelled';
          case 'O':
            return 'Open';
          case 'Z':
            return 'Easy';
          default:
            return 'N/A';
        }
      }
    };
}




