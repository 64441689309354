export class User {
  public Email: string;
  public Password: string;
  public Handle: string;
  public Verified: boolean;
  public Token: string;
  public Ticket: string;
  public Roles: number[];
  public MPIDs: string[];
  public RequiredAction: number;
  public Message: string;
  public idUser: number;
  public idEntity: number;
  public FirebaseUid: string;

  constructor(data: any) {
    this.Email = data.Email;
    this.Password = data.Password;
    this.Handle = data.Handle;
    this.Verified = data.Verified;
    this.Token = data.Token;
    this.Ticket = data.Ticket;
    this.Roles = data.Roles;
    this.MPIDs = data.MPIDs;
    this.RequiredAction = this.RequiredAction;
    this.Message = data.Message;
    this.idUser = data.idUser;
    this.idEntity = data.idEntity;
    this.FirebaseUid = data.FirebaseUid;
  }

  public isManager(): boolean {
    return (this.Roles.indexOf(6) > -1);
  }
}
