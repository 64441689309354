export class InventoryRequest {
  public idRequest: number;
  public idUser: number;
  public security: string;
  public quantity: number;

  constructor(data: any) {
    this.idRequest = data.idRequest;
    this.idUser = data.idUser;
    this.security = data.security;
    this.quantity = data.quantity;
  }

}
