import {Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {LocateStreamService} from './stream/locate-stream.service';
import {LocategridComponent} from './locategrid/locategrid.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../authentication/auth.service';
import {WatchlistComponent} from '../watchlist/watchlist.component';
import {User} from '../authentication/User/user';
import {HttpClient} from '@angular/common/http';
import {WatchSymbol} from '../watchlist/watchSymbol/watchSymbol';
import {Order} from './order';
import {environment} from '../../environments/environment';
import 'rxjs/add/observable/interval';
import {VpsOrder} from './vpsOrder';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import {BulkImportDialogComponent} from '../shared/dialog/bulk-Import-dialog.component';

@Component({
  selector: 'app-locates',
  templateUrl: './locates.component.html',
  styleUrls: ['./locates.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class LocatesComponent implements OnInit {
  @ViewChild('locateGrid1', {static: false}) locateGrid: LocategridComponent;
  @ViewChild('watchList', {static: false}) watchList: WatchlistComponent;
  @ViewChild('symbolInput', {static: false}) symbol: ElementRef;
  @ViewChild('csvInput', {static: false}) csvInput: ElementRef;
  public formLocator: FormGroup;
  public currentUser: User;
  private traders: string[];
  public quote: WatchSymbol;
  public parsedCsv;
  mpid: string;

  constructor(private locateStreamService: LocateStreamService,
              private fb: FormBuilder,
              private authService: AuthService,
              private httpClient: HttpClient,
              public dialog: MatDialog) {
  }

  ngOnInit() {
    this.formLocator = this.fb.group({
      trader: [null, Validators.compose(null)],
      symbol: [null, Validators.compose(null)],
      quantity: [null, Validators.compose(null)],
      mpid: [null, Validators.compose(null)]
    });
    this.currentUser = new User(JSON.parse(localStorage.getItem('User')));
    if (this.currentUser.isManager()) {
      this.locateStreamService.subscribeManager();
    } else {
      // this.locateStreamService.subscribeByMpids(this.currentUser.MPIDs);
      this.locateStreamService.subscribeTraderID(this.currentUser.Handle);
    }
    this.traders = [];
    this.quote = null;
    this.formLocator.get('quantity').setValue(100);
    if (this.currentUser.MPIDs.length === 1) {
      this.mpid = this.currentUser.MPIDs[0];
    }
  }

  public exportGrid() {
    this.locateGrid.onExportButton();
  }

  public onToggleFilterChanged(value) {
    this.locateGrid.SetFilter(value);
  }

  public GetQuote($event) {
    // const row = this.locateGrid.gridApi.getDisplayedRowAtIndex(1);
    // row.setDataValue('MinutesRemaining', 10);
    // var sub = Observable.interval(30000)
    //   .subscribe((val) => {
    //     const row = this.locateGrid.gridApi.getDisplayedRowAtIndex(1);
    //     row.setDataValue('MinutesRemaining', row.data.MinutesRemaining - 0.5);
    //   });
    if ($event.key === 'Enter' || $event.key === 'Tab') {
      const self = this;
      const symbol: string = this.formLocator.get('symbol').value;
      if (symbol != null && symbol.trim().length > 0) {
        this.watchList.GetQuote({Symbol: symbol}).then(function (val) {
          self.quote = val;
        });
      } else {
        self.quote = null;
      }
    }

    if (this.formLocator.get('symbol').value.length === 0) {
      this.formLocator.controls['symbol'].setErrors(null);
      this.quote = null;
    }

    // if ($event.key === 'Enter') {
    //   $event.target.selectionStart = 0;
    //   // this.watchList.AddSymbolToWatchlist({Symbol: this.formLocator.get('symbol').value});
    // }
    // this.formLocator.reset();
  }


  public doBulkNewOrders(list: Order[]) {
    // if (!this.isValidateOrder()) {
    //   return;
    // }

    this.httpClient.post(environment.locateApiEndpoint + 'locate/bulkRequest',
      {items: list}, {
        headers: {
          Authorization: `Bearer ${JSON.parse(JSON.stringify(this.authService.afAuth.auth.currentUser)).stsTokenManager.accessToken}`,
          'Content-Type': 'application/json'
        }
      }).subscribe(function (val) {
    });
    this.locateGrid.gridApi.deselectAll();
    this.quote = null;
  }

  public doNewOrder(order: Order) {
    // if (!this.isValidateOrder()) {
    //   return;
    this.httpClient.post(environment.locateApiEndpoint + 'locate/request',
      order, {
        headers: {
          Authorization: `Bearer ${JSON.parse(JSON.stringify(this.authService.afAuth.auth.currentUser)).stsTokenManager.accessToken}`,
          'Content-Type': 'application/json'
        }
      }).subscribe(function (val) {
    });

    const tempMpid = this.mpid;
    this.locateGrid.gridApi.deselectAll();
    this.formLocator.reset();
    this.formLocator.markAsPristine();
    this.clearAllValidationErrors();
    this.formLocator.get('quantity').setValue(100);
    this.mpid = tempMpid;
    this.formLocator.get('mpid').setValue(this.mpid);
    this.symbol.nativeElement.focus();
    this.quote = null;
  }

  openBulkDialog() {
    const dialogRef = this.dialog.open(BulkImportDialogComponent,
      {
        height: 'auto',
        width: 'auto',
        minHeight: '100px',
        maxWidth: '500px',
        panelClass: 'custom-dialog',
        data: {
          groupCodes: this.currentUser.MPIDs
        }
      });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.mpid = result;
        this.csvInput.nativeElement.click();
      }
    });
  }

  createOrder(symbol: string, quantity: number): Order {
    const user: User = JSON.parse(localStorage.getItem('User'));
    const ord: Order = new Order({
      Symbol: symbol,
      OrdQty: quantity,
      OrdType: 2,
      OrderStatus: 5,
      Trader: user.Handle,
      MPID: user.MPIDs[0]
    });
    return ord;
  }

  public NewOrder() {
    const order: Order = this.createOrder(this.formLocator.get('symbol').value.toUpperCase().trim(),
      this.formLocator.get('quantity').value);
    this.doNewOrder(order);
  }

  HandleEnter($event) {
    if ($event.key === 'Enter') {
      this.NewOrder();
    }
  }

  public ProcessOrders(action: string) {
    const orders: VpsOrder[] = [];
    this.locateGrid.getSelectedRows().forEach(function (value) {
      orders.push(value);
    });

    this.httpClient.post(environment.locateApiEndpoint + 'locate/bulk' + action,
      {items: orders}, {
        headers: {
          Authorization: `Bearer ${JSON.parse(JSON.stringify(this.authService.afAuth.auth.currentUser)).stsTokenManager.accessToken}`,
          'Content-Type': 'application/json'
        }
      }).subscribe(function (val) {
    });
    this.locateGrid.gridApi.deselectAll();
    this.quote = null;
  }

  // public ProcessOrders(intention: string) {
  //   const self = this;
  //   this.locateGrid.getSelectedRows().forEach(function (value) {
  //     const user: User = JSON.parse(localStorage.getItem('User'));
  //     const param = {
  //       ticket: user.Ticket,
  //       intention: intention,
  //       params: value
  //     };
  //     self.httpClient.post(environment.locateApiEndpoint + 'orders',
  //       param).subscribe(
  //       (val) => {
  //         console.log(val);
  //       },
  //       response => {
  //         console.log(response);
  //       },
  //       () => {
  //         self.locateGrid.hasRowsSelected = false;
  //       });
  //   });
  // }

  public isValidateOrder(): boolean {
    let isValid = true;
    const qty: number = this.formLocator.get('quantity').value;
    const symbol: string = this.formLocator.get('symbol').value;

    if (qty < 100 || qty === null) {
      this.formLocator.get('quantity').setErrors({'invalidQuantity': true});
      isValid = false;
    }

    if (symbol === null || symbol.trim().length === 0) {
      this.formLocator.get('symbol').setErrors({'invalidSymbol': true});
      isValid = false;
    }

    return isValid;
  }

  csvInputChange(fileInputEvent: any) {
    const file = fileInputEvent.target.files[0];
    const fileReader = new FileReader();
    const orders: Order[] = [];
    fileReader.onload = this.onFileLoad;
    fileReader.readAsText(file, 'UTF-8');
    fileReader.onloadend = (e) => {
      const textFromFileLoaded = fileReader.result;
      const csvSeparator = ';';
      let txt;
      if (typeof textFromFileLoaded === 'string') {
        txt = textFromFileLoaded.trim().replace(/(^[\t]*\n)/gm, '');
      }
      const csv = [];
      let cols: string[];
      const lines = txt.split('\r');
      for (let i = 1; i < lines.length; i++) {
        cols = lines[i].split(csvSeparator);
        const request = cols.toString().split(',');
        orders.push(this.createOrder(request[0].trim(), Number(request[1].trim())));
      }
      this.doBulkNewOrders(orders);
    };
  }

  onFileLoad(fileLoadedEvent) {
  }

  clearAllValidationErrors() {
    // tslint:disable-next-line:forin
    for (const name in this.formLocator.controls) {
      this.formLocator.controls[name].setErrors(null);
    }
  }
}
