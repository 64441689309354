import 'ag-grid-community';
import {Component, Inject, Injectable, OnDestroy, OnInit} from '@angular/core';
import {LocateStreamService} from '../stream/locate-stream.service';
import {GridOptions} from 'ag-grid-community';
import {CellFormatter} from '../../shared/grid/cell-formatter';
import {Subscription} from 'rxjs';
import {Utils} from '../../shared/utils';
import {User} from '../../authentication/User/user';
import {AuthService} from '../../authentication/auth.service';
import {VpsOrder} from '../vpsOrder';

@Component({
  selector: 'app-locategrid',
  templateUrl: './locategrid.component.html',
  styleUrls: ['./locategrid.component.scss']

})

export class LocategridComponent implements OnInit, OnDestroy {
  public gridApi;
  private gridColumnApi;
  public gridOptions: GridOptions;
  private _sub: Subscription;
  public hasRowsSelected = false;
  public currentUser: User;
  public isManager;

  constructor(private locateStreamService: LocateStreamService) {
    this.currentUser = new User(JSON.parse(localStorage.getItem('User')));
    this.isManager = this.currentUser.isManager();
    this.defineColumns();
    this.defineGridOptions();
  }

  getContextMenuItems(params) {
    const result = [
      'copy',
      // 'csvExport'
    ];
    return result;
  }

  // Grid Events
  gridOnReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    if (this.gridApi) {
      this._sub = this.locateStreamService.order$.subscribe(data => {
        // console.log(item);
        // this.gridApi.updateRowData({add: item});
        this.gridApi.setRowData(data);
      });
      this.gridApi.sizeColumnsToFit();
    }
  }

  gridSizeChanged(params) {
    this.gridApi.sizeColumnsToFit();
    // Hack to hide horizontal scroll bar
    const colState = this.gridColumnApi.getColumnState();
    this.gridColumnApi.setColumnWidth(colState[colState.length - 1].colId, colState[colState.length - 1].width - 20);
  }

  public onExportButton() {
    const exportFileName = Utils.createFileName('locates-', new Date(), '', '');
    const params = {
      fileName: exportFileName
    };
    // this.gridApi.exportDataAsExcel(params);
    this.gridApi.exportDataAsCsv(params);
  }

  public SetFilter(value: string) {
    const ordTypeFilled = this.gridApi.getFilterInstance('Status');
    if (value === '') {
      ordTypeFilled.setModel({});
    } else {
      ordTypeFilled.setModel({
        filterType: 'set',
        //type: 'equals',
        values: [value],
        newRowsAction: 'keep'
      });
    }
    this.gridApi.onFilterChanged();
  }

  public getSelectedRows(): any {
    return this.gridApi.getSelectedRows();
  }

  public applyManagerSettings() {
  }

  gridOnCellMouseOut(event) {
  }

  gridOnCellMouseOver(event) {
  }

  gridOnRowSelected(event): boolean {
    this.hasRowsSelected = this.gridApi.getSelectedRows().length > 0;
    return this.hasRowsSelected;
  }

  // component events
  ngOnInit() {
  }

  ngOnDestroy() {
    if (this._sub) {
      this._sub.unsubscribe();
    }
  }

  private defineGridOptions() {
    const columns: any = this.defineColumns();
    this.gridOptions = {};
    this.gridOptions.defaultColDef = this.defineDefaultColumns();
    this.gridOptions.columnDefs = columns;
    this.gridOptions.floatingFilter = true;
    this.gridOptions.groupIncludeTotalFooter = false;
    this.gridOptions.rowSelection = 'multiple';
    this.gridOptions.suppressScrollOnNewData = true;
    this.gridOptions.suppressRowClickSelection = true;
    this.gridOptions.enableRangeSelection = true;


    // function assignments
    this.gridOptions.getRowNodeId = (params) => this.getRowNodeId(params);
    this.gridOptions.getRowClass = (params) => {
      switch (params.data.statusStyle) {
        case 'status-open':
        case 'status-pend':
          return params.data.statusStyle;
        default:
          return '';
      }
    };

    // this.gridOptions.getRowClass = (params) => CellFormatter.statusStyle(params);
    this.gridOptions.onGridReady = (event) => this.gridOnReady(event);
    this.gridOptions.getContextMenuItems = (event) => this.getContextMenuItems(event);
    this.gridOptions.onCellMouseOver = (event) => this.gridOnCellMouseOver(event);
    this.gridOptions.onCellMouseOut = (event) => this.gridOnCellMouseOut(event);
    // this.gridOptions.isRowSelectable = function (rowData) {
    //   return rowData.data ? (rowData.data.locateStatus === 5 || rowData.data.locateStatus === 0) && rowData.data.idSource === 0 : false;
    // };
    this.gridOptions.isRowSelectable = function (rowData) {
      return rowData.data.isSelectable;
    };
    this.gridOptions.onRowSelected = (event) => this.gridOnRowSelected(event);
  }

  private getRowNodeId(data) {
    return data.idOrder;
  }

  private defineColumns() {
    return [
      {
        minWidth: 40, maxWidth: 40, field: 'checkbox', pinned: true,
        checkboxSelection: true, headerCheckboxSelection: true
      },
      {
        headerName: 'Time', minWidth: 120, maxWidth: 120, field: 'time', pinned: true, sort: 'desc'
      },
      {
        headerName: 'Symbol',
        minWidth: 110,
        maxWidth: 110,
        field: 'Symbol',
        pinned: true,
        filter: 'agTextColumnFilter',
        filterParams: {newRowsAction: 'keep'}
      },
      {
        headerName: 'Trader',
        minWidth: 150,
        maxWidth: 150,
        field: 'Trader',
        pinned: true,
        filter: 'agSetColumnFilter',
        suppressMenu: false,
        menuTabs: ['filterMenuTab'],
        filterParams: {newRowsAction: 'keep'}
      },
      {
        headerName: 'MPID',
        field: 'MPID',
        minWidth: 90,
        filterParams: {newRowsAction: 'keep'},
        maxWidth: 90,
        headerTooltip: 'MPID',
        filter: 'agSetColumnFilter',
        pinned: true,
        suppressMenu: false,
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: 'OrdQty', minWidth: 90, maxWidth: 90, field: 'OrdQty',
        valueFormatter: CellFormatter.num, type: 'numericColumn'
      },
      {
        headerName: 'OrdPx', minWidth: 90, maxWidth: 90, field: 'OrdPx',
        valueFormatter: CellFormatter.num, type: 'numericColumn'
      },
      {
        headerName: 'OfferQty', minWidth: 90, maxWidth: 90, field: 'OfferQty',
        valueFormatter: CellFormatter.num, type: 'numericColumn'
      },
      {
        headerName: 'OfferPx', minWidth: 80, maxWidth: 80, field: 'OfferPx', type: 'numericColumn'
      },
      {
        headerName: 'ExecQty', minWidth: 90, maxWidth: 90, field: 'ExecQty',
        valueFormatter: CellFormatter.num, type: 'numericColumn'
      },
      {
        headerName: 'ExecPx', minWidth: 90, maxWidth: 90, field: 'ExecPx',
        valueFormatter: CellFormatter.num, type: 'numericColumn'
      },
      {
        headerName: 'LocateId', minWidth: 80, maxWidth: 80, field: 'idOrder',
        type: 'numericColumn', filter: 'agTextColumnFilter', filterParams: {newRowsAction: 'keep'},
      },
      {
        headerName: 'Status',
        minWidth: 120,
        colId: 'Status',
        maxWidth: 120,
        filter: 'agSetColumnFilter',
        field: 'locateStatusText',
        filterParams: {newRowsAction: 'keep'},
        cellStyle: LocategridComponent.cellstyle,
        suppressMenu: false,
        menuTabs: ['filterMenuTab']
      }
      // {
      //   headerName: 'Status',
      //   minWidth: 120,
      //   colId: 'Status',
      //   maxWidth: 120,
      //   filter: 'agSetColumnFilter',
      //   field: 'locateStatusText',
      //   filterParams: {newRowsAction: 'keep'},
      //   suppressMenu: false,
      //   menuTabs: ['filterMenuTab'],
      //   cellStyle: LocategridComponent.cellstyle
      // }
    ];
  }

  private defineDefaultColumns() {
    return {
      enableFilter: true,
      sortable: true,
      editable: false,
      suppressMenu: true,
      floatingFilter: true,
      resizable: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true
      },
      headerCheckboxSelectionFilteredOnly: true
    };

  }


  static cellstyle(params) {
    switch (params.data.statusStyle) {
      case 'status-fill':
        return {'color': 'white', 'background-color': '#689f38'};
      case 'status-open':
        return {'color': 'black', 'background-color': '#F57C00'};
      case 'status-unprocessed':
        return {'color': 'white'};
      case 'status-pend':
        return {'color': 'black', 'background-color': '#c5e1a5'};
      case 'status-cancel':
        return {'color': 'black', 'background-color': '#959595'};
      case 'status-rejected':
        return {'color': '#b0b1ae', 'background-color': '#344428'};
      case 'status-overnight':
        return {'color': '#e1ffb1', 'background-color': '#33691'};
    }
  }
}
